import Head from "next/head";

interface SEOProps {
  title?: string;
  description?: string;
  ogTitle?: string;
  ogDescription?: string;
  ogType?: string;
  ogSiteName?: string;
  ogImage?: string;
  twitterTitle?: string;
  twitterDescription?: string;
  twitterImage?: string;
}

export default function SEO(props: SEOProps) {
  const title = props.title ?? "Mailbrew";
  const description =
    props.description ?? "Create beautiful, automated newsletters with content from the sites and apps you love.";
  const ogTitle = props.ogTitle ?? title;
  const ogDescription = props.ogDescription ?? description;
  const ogType = props.ogType ?? "website";
  const ogSiteName = props.ogSiteName ?? "Mailbrew";
  const ogImage = props.ogImage ?? "https://mailbrew.com/card.png";
  const twitterTitle = props.twitterTitle ?? ogTitle;
  const twitterDescription = props.twitterDescription ?? ogDescription;
  const twitterImage = props.twitterImage ?? ogImage;

  return (
    <Head>
      {/* Primary Meta Tags */}
      {title && <title>{title}</title>}
      {title && <meta name="title" content={title} />}
      {description && <meta name="description" content={description} />}

      {/* Open Graph / Facebook */}
      {ogType && <meta property="og:type" content={ogType} key="og:type" />}
      {ogSiteName && <meta property="og:site_name" content={ogSiteName} key="og:site_name" />}
      {/* <meta property="og:url" content="https://metatags.io/" /> key="og:url"*/}
      {ogTitle && <meta property="og:title" content={ogTitle} key="og:title" />}
      {ogImage && <meta property="og:image" content={ogImage} key="og:image" />}
      {ogDescription && <meta property="og:description" content={ogDescription} key="og:description" />}

      {/* Twitter */}
      {twitterImage && <meta property="twitter:card" content="summary_large_image" key="twitter:card" />}
      {twitterTitle && <meta property="twitter:title" content={twitterTitle} key="twitter:title" />}
      {twitterDescription && (
        <meta property="twitter:description" content={twitterDescription} key="twitter:description" />
      )}
      {twitterImage && <meta property="twitter:image" content={twitterImage} key="twitter:image" />}
    </Head>
  );
}
