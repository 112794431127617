import mixpanel from "mixpanel-browser";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { authUserSelector } from "reducers/authReducer";
import { isDevelopment } from "utils/env";
import plausible from "utils/plausible";

const MixpanelInject = () => {
  const user = useSelector(authUserSelector);
  const [initialized, setInitialized] = useState(false);
  const router = useRouter();

  useEffect(() => {
    mixpanel.init("37e00e7cd4fae77984068e7e374e6b4a");
    setInitialized(true);

    // disable in dev
    if (isDevelopment) {
      mixpanel.disable();
    }
  }, []);

  // identify user
  useEffect(() => {
    if (initialized && user?.id) {
      if (isDevelopment) mixpanel.disable();
      mixpanel.identify(user.id);
    }
  }, [initialized, user?.id]);

  // track navigations
  useEffect(() => {
    if (!user) return;
    if (!router.asPath) return;

    if (user && initialized && router.asPath) {
      mixpanel.track(`Page View`, {
        pathname: router.asPath,
      });
      plausible.track(`Page View`, {
        pathname: router.asPath,
      });
    }
  }, [initialized, router.asPath, user]);

  return null;
};

export default MixpanelInject;
